@import url('https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700&display=swap');
* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

body {
    color: #202529;
    font-family: 'Lato', sans-serif;
    font-weight: 300;
}

a {
    color: inherit;
}

img {
    max-width: 100%;
    height: auto;
}

p {
    line-height: 1.5;
    margin-bottom: 1em;
}

.grecaptcha-badge {
    visibility: hidden;
}
